import React, { useState, useEffect } from 'react'

import { Link, NavLink, useLocation } from 'react-router-dom'

function Nav() {
  const location = useLocation()
  useEffect(() => {
    setIsMenuVisible(false)
  }, [location])
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const toggle = () =>
    setIsMenuVisible(curr => {
      return !curr
    })
  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="align-middle" role="img" aria-label="basketball">
                <Link
                  to="/"
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  <i className="text-white font-extrabold">
                    <span role="img" aria-label="basketball">
                      🏀
                    </span>
                    <span className="ml-2">dashette</span>
                  </i>
                </Link>
              </span>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline">
                <NavLink
                  to="/"
                  exact={true}
                  activeClassName="bg-gray-900 text-white"
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Scores
                </NavLink>
                <NavLink
                  to="/standings"
                  activeClassName="bg-gray-900 text-white"
                  className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Standings
                </NavLink>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggle}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={`${isMenuVisible ? 'hidden' : 'inline-flex'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${!isMenuVisible ? 'hidden' : 'inline-flex'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          isMenuVisible ? 'block' : 'hidden'
        } md:hidden transition duration-1000 ease-in-out`}
      >
        <div className="px-2 pt-2 pb-3 sm:px-3 flex flex-col">
          <NavLink
            to="/"
            exact={true}
            activeClassName="bg-gray-900 text-white"
            className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >
            Scores
          </NavLink>
          <NavLink
            to="/standings"
            activeClassName="bg-gray-900 text-white"
            className="mt-1 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >
            Standings
          </NavLink>
        </div>
      </div>
    </nav>
  )
}

export default Nav

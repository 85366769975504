/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://namn5qfqwnfefovsxub6ko6hki.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-p7ouy4f5l5b7nouhfpxo7vlgnu",
    "aws_cognito_identity_pool_id": "us-east-1:8ac50f5b-adf6-4e14-b32f-9a0eae95fd36",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "f690583d3c024b0980bca46944aad90b",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;

import React from 'react'
import Amplify from '@aws-amplify/core'
import awsconfig from './aws-exports'
import moment from 'moment-timezone'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import Nav from './Nav'
import ScoreBoard from './Scores'
import Standings from './Standings'
import Game from './Game'

Amplify.configure(awsconfig)

function App() {
  return (
    <div>
      <Router>
        <div className="border-t-4 border-blue-300">
          <Nav />
        </div>
        <Switch>
          <Route exact path={['/']}>
            <Redirect to={`/scores/${moment().format('YYYYMMDD')}`} />
          </Route>
          <Route exact path={['/scores/:date']}>
            <ScoreBoard />
          </Route>
          <Route exact path="/standings">
            <Standings />
          </Route>
          <Route exact path="/scores/:date/:gameId">
            <Game />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App

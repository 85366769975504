import React, { useState, useEffect } from 'react'
import { useQuery } from './constants'
import { boxscore } from './graphql/queries'

const playerStats = [
  p => (
    <span className="">{`${p['firstName'].substr(0, 1)}. ${
      p['lastName']
    }`}</span>
  ),
  p => (
    <>
      <span className="">{p['min'].split(':')[0]}</span>
      {/* <span className="hidden md:inline">{p['min']}</span> */}
    </>
  ),
  p => `${p['fgm']}-${p['fga']}`,
  p => `${p['tpm']}-${p['tpa']}`,
  p => `${p['ftm']}-${p['fta']}`,
  'totReb',
  'assists',
  'pFouls',
  'points',
  'plusMinus'
]

const footerStats = [
  null,
  p => (
    <>
      <span className="">{p['min'].split(':')[0]}</span>
      {/* <span className="hidden md:inline">{p['min']}</span> */}
    </>
  ),
  p => `${p['fgm']}-${p['fga']}`,
  p => `${p['tpm']}-${p['tpa']}`,
  p => `${p['ftm']}-${p['fta']}`,
  'totReb',
  'assists',
  'pFouls',
  'points',
  'plusMinus'
]

const headers = [
  'Player',
  'Min',
  'FG',
  '3PT',
  'FT',
  'Reb',
  'Ast',
  'PF',
  'Pts',
  '+/-'
]

const css = [
  'text-left w-1/4 truncate',
  'text-right whitespace-no-wrap',
  'text-right whitespace-no-wrap',
  'text-right whitespace-no-wrap',
  'text-right whitespace-no-wrap hidden md:table-cell',
  'text-right whitespace-no-wrap',
  'text-right whitespace-no-wrap',
  'text-right whitespace-no-wrap',
  'text-right font-semibold whitespace-no-wrap',
  'text-right whitespace-no-wrap hidden lg:table-cell'
]

const toTD = (p, accessor) => {
  if (!accessor) {
    return null
  } else if (typeof accessor === 'string') {
    return p[accessor]
  } else {
    return accessor(p)
  }
}

function TeamBoxScore({ team, teamStats, players, isGameActivated }) {
  const [all, setAll] = useState(false)

  return (
    <section className="mt-4">
      <div className="flex justify-between items-center mb-2">
        <h2 className="uppercase font-bold tracking-wider">
          {team.team.fullName}
        </h2>
        <div className="flex items-center">
          <div
            onClick={() => setAll(a => !a)}
            className={`bg-gray-200 relative w-6 h-3 p-1 rounded-full cursor-pointer`}
          >
            <div
              style={{
                right: all ? '0' : '100%',
                marginRight: all ? '' : '-0.75rem'
              }}
              className={`block absolute top-0 h-3 w-3 p-0 m-0 rounded-full bg-blue-500 focus:outline-none transition-all duration-100 ease-linear`}
            ></div>
          </div>
          <button
            onClick={() => setAll(a => !a)}
            className="ml-1 focus:outline-none"
          >
            all players
          </button>
        </div>
      </div>
      <table className="table-fixed w-full text-xs lg:text-sm">
        <thead className="border-b-4 uppercase tracking-wide text-gray-600 font-extrabold">
          <tr>
            {headers.map((h, i) => (
              <th className={`${css[i]} py-2  px-1 lg:px-2`} key={h}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {players
            .filter((p, i) => (all ? p : isGameActivated ? p.isOnCourt : i < 5))
            .slice(0, all ? undefined : 5)
            .map((p, i) => {
              return (
                <tr
                  className={`${i % 2 === 0 ? '' : 'bg-blue-100'} ${
                    all && !isGameActivated && i === 4
                      ? 'border-b border-red-600'
                      : ''
                  }`}
                  key={p.personId}
                >
                  {playerStats.map((a, j) => (
                    <td className={`${css[j]} py-3 px-1 lg:px-2`} key={j}>
                      {toTD(p, a)}
                    </td>
                  ))}
                </tr>
              )
            })}
        </tbody>
        {false && all && teamStats ? (
          <tfoot>
            <tr>
              {footerStats.map((a, i) => (
                <th className={`${css[i]} py-2 px-1 lg:px-2`} key={i}>
                  {toTD(teamStats.totals, a)}
                </th>
              ))}
            </tr>
          </tfoot>
        ) : null}
      </table>
    </section>
  )
}

function BoxScore({ viewId, className, isCurrent, date, game }) {
  const [more, setMore] = useState(true)
  const [{ result }, executeQuery] = useQuery({
    query: boxscore,
    path: 'boxscore.stats',
    variables: { query: { date, gameId: game.gameId } },
    def: { activePlayers: [] }
  })

  useEffect(() => {
    if (isCurrent && more) {
      console.log('get boxscore')
      executeQuery()
    }
    return () => {
      console.log('umount boxscore')
      if (isCurrent && !game.isGameActivated) {
        console.log('no more boxscore')
        setMore(false)
      }
    }
  }, [executeQuery, isCurrent, game.isGameActivated, more])

  console.log('box score ->', result)
  const teamsZip = result.activePlayers.reduce(
    (zip, player) => {
      if (player.teamId === game.hTeam.teamId) {
        zip.hTeam.push(player)
      } else {
        zip.vTeam.push(player)
      }
      return zip
    },
    { vTeam: [], hTeam: [] }
  )

  return (
    <div className={`boxscore ${isCurrent ? '' : 'hidden'}`}>
      <div className=" bg-white p-2 rounded-lg shadow-md border">
        <TeamBoxScore
          team={game.vTeam}
          teamStats={result.vTeam}
          players={teamsZip.vTeam}
          isGameActivated={game.isGameActivated}
        />
        <TeamBoxScore
          team={game.hTeam}
          teamStats={result.hTeam}
          players={teamsZip.hTeam}
          isGameActivated={game.isGameActivated}
        />
      </div>
    </div>
  )
}

export default BoxScore

const teamFragment = `
teamId
win
loss
triCode
score
linescore {
  score
}
team {
  fullName
  nickname
}`

export const aPlay = `
clock
teamId
vTeamScore
hTeamScore
index
isScoreChange
formatted {
  description
}
`

const playFragment = `
lastPlay {
  ${aPlay}
}
`

export const selectionSet = `
numGames
games {
  ${playFragment}
  nugget {
    text
  }
  period{
    current
    type
    maxRegular
    isHalftime
    isEndOfPeriod
  }
  watch {
    broadcast {
      broadcasters {
        national {
          shortName
          longName
        }
      }
    }
  }
  seasonStageId
  seasonYear
  gameId
  isGameActivated
  startTimeUTC
  startDateEastern
  endTimeUTC
  clock
  attendance
  vTeam {
    ${teamFragment}
  }
  hTeam {
    ${teamFragment}
  }
}
`

export const teamStandingSelectionSect = `
teamId
win
loss
winPct
gamesBehind
clinchedPlayoffsCode
clinchedPlayoffsCodeV2
confRank
lastTenWin
lastTenLoss
isWinStreak
teamSitesOnly {
  teamTricode
  teamNickname
  streakText
}
`

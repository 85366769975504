import React from 'react'
import { useParams, Link } from 'react-router-dom'
import ScoreCard from './ScoreCard'
import { useScores, toM, Loading } from './constants'
import Helmet from 'react-helmet'
import Analytics from '@aws-amplify/analytics'

function DateNavigationButton(props) {
  const { children, className, to, ...rest } = props
  const cn =
    'inline-block text-bold bg-blue-300 hover:bg-blue-400 shadow focus:outline-none focus:shadow-outline font-bold px-3 py-1 rounded-full'
  const cn1 = `${cn}${className ? ' ' + className : ''}`
  return (
    <Link to={`/scores/${to.format('YYYYMMDD')}`} className={cn1} {...rest}>
      {children}
    </Link>
  )
}

function ScoreBoard() {
  const { date } = useParams()
  const { scores, isCurrent, fetching } = useScores(date)
  const nextDay = () => toM(date).add(1, 'day')
  const prevDay = () => toM(date).subtract(1, 'day')

  Analytics.record({
    name: 'scoresVisit',
    attributes: { date },
  })

  return (
    <>
      <Helmet>
        <title>Dashette - NBA Scores - {toM(date).format('L')}</title>
        <meta
          name="description"
          content={`NBA scores on ${toM(date).format('L')}`}
        />
      </Helmet>
      <div className="max-w-6xl px-4 mx-auto my-4 antialiased text-gray-800 md:my-8">
        <div className="flex items-center justify-center">
          <div className="mr-2">
            <h1 className="text-2xl font-bold text-center text-gray-800 whitespace-no-wrap">
              Scores
            </h1>
          </div>
          <div className="ml-2">
            <span
              className={`${
                isCurrent
                  ? 'bg-green-300 text-green-700'
                  : 'bg-red-300 text-red-700'
              } transition duration-1000 ease-in-out text-xs tracking-wide uppercase font-extrabold py-1 px-3 rounded-full whitespace-no-wrap`}
            >
              &bull; {fetching ? 'fetching' : scores.numGames || 'no'} games
            </span>
          </div>
        </div>
        {fetching ? (
          <Loading />
        ) : (
          <>
            <div className="flex items-center justify-center max-w-md mx-auto my-4">
              <div>
                <DateNavigationButton to={prevDay()}>
                  <svg viewBox="0 0 20 20" className="w-4">
                    <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                  </svg>
                </DateNavigationButton>
              </div>
              <div className="w-1/2 mx-8 text-center">
                {toM(date).format('dddd, MMM D')}
              </div>
              <div>
                <DateNavigationButton to={nextDay()}>
                  <svg viewBox="0 0 20 20" className="w-4">
                    <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                  </svg>
                </DateNavigationButton>
              </div>
            </div>
            {scores.numGames > 0 ? (
              <div className="grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                {scores.games.map((game) => (
                  <ScoreCard game={game} key={game.gameId} />
                ))}
              </div>
            ) : (
              <h2 className="w-1/2 mx-auto text-xs font-extrabold tracking-wide text-center text-gray-600 uppercase">
                No games on this day.
              </h2>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ScoreBoard

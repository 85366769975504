import React, { useState, useEffect } from 'react'
import API from '@aws-amplify/api'
import _get from 'lodash.get'
import Helmet from 'react-helmet'
import * as queries from './graphql/queries'

function Hot({ team }) {
  // const blocks = []
  // for (let i = 0; i < team.lastTenWin; i++) {
  //   blocks.push(<div className="bg-green-200 h-4" key={i}></div>)
  // }
  // for (let i = 0; i < team.lastTenLoss; i++) {
  //   blocks.push(
  //     <div className="bg-red-200 h-4" key={team.lastTenWin + i}></div>
  //   )
  // }
  // return (
  //   <span className="align-block rounded shadow">
  //     <span class="grid grid-cols-10 gap-0">{blocks}</span>
  //   </span>
  // )
  return (
    <div className="flex items-stretch h-4 w-full shadow-lg text-xs">
      <div
        className="bg-green-200 text-center"
        style={{ width: `${team.lastTenWin}0%` }}
      >
        <span className="hidden md:inline">{team.lastTenWin}</span>
      </div>
      <div
        className="bg-red-200 text-center"
        style={{ width: `${team.lastTenLoss}0%` }}
      >
        <span className="hidden md:inline">{team.lastTenLoss}</span>
      </div>
    </div>
  )
}

function Streak({ team }) {
  const cn = `${
    team.isWinStreak ? 'bg-green-200' : 'bg-red-200'
  } rounded-full font-extrabold px-2 md:px-4 py-1 text-xs md:text-sm text-gray-600 whitespane-no-wrap`
  return <span className={cn}>{team.teamSitesOnly.streakText}</span>
}

function Conference({ conf, title }) {
  const cellSpacing = 'px-2 md:px-4 py-1 md:py-2 text-sm md:text-base'
  const cellSpacingRight = cellSpacing + ' text-right'
  const cellSpacingCenter = cellSpacing + ' text-center'
  return (
    <div className="my-4">
      <h2 className="uppercase text-base font-bold text-gray-800 tracking-wider mb-2">
        {title}
      </h2>
      <div className="rounded-md shadow p-0">
        <table className="table-auto mx-auto w-full border-collapse font-roboto">
          <thead className="bg-blue-100 border-b-4 uppercase tracking-wide text-sm text-gray-600 font-extrabold">
            <tr>
              <th className={`${cellSpacing}`}></th>
              <th className={`${cellSpacing}`}></th>
              <th className={`${cellSpacingRight}`}>
                <span className="hidden md:inline-block">Win</span>
                <span className="md:hidden">W</span>
              </th>
              <th className={`${cellSpacingRight}`}>
                <span className="hidden md:inline-block">Loss</span>
                <span className="md:hidden">L</span>
              </th>
              <th className={`${cellSpacingRight} hidden md:table-cell`}>
                <span className="hidden md:inline-block">Win%</span>
                <span className="md:hidden">%</span>
              </th>
              <th className={`${cellSpacingRight}`}>GB</th>
              <th className={`${cellSpacingRight}`}>L10</th>
              <th className={`${cellSpacingCenter}`}>Streak</th>
            </tr>
          </thead>
          <tbody className="">
            {conf.map((team, i) => (
              <tr
                key={team.teamId}
                className={`${i % 2 === 0 ? '' : 'bg-blue-100'} ${
                  team.confRank === '8' ? 'border-b border-red-600' : ''
                }`}
              >
                <th scope="row" className={`${cellSpacing} w-4 md:text-2xl`}>
                  {team.confRank}
                </th>
                <td className={`${cellSpacing} whitespace-no-wrap`}>
                  <img
                    src={`https://cdn.nba.net/assets/logos/teams/secondary/web/${team.teamSitesOnly.teamTricode}.svg`}
                    alt={team.teamSitesOnly.teamTricode}
                    className="h-8 inline-block"
                  />
                  <span className="ml-2 md:hidden inline-block">
                    {team.teamSitesOnly.teamTricode}
                  </span>
                  <span className="ml-4 hidden md:inline-block">
                    {team.teamSitesOnly.teamNickname}
                  </span>
                  <span className="text-red-600 text-xs font-extrabold uppercase align-top">
                    {team.clinchedPlayoffsCodeV2}
                  </span>
                </td>
                <td className={`${cellSpacingRight}`}>{team.win}</td>
                <td className={`${cellSpacingRight}`}>{team.loss}</td>
                <td className={`${cellSpacingRight} hidden md:table-cell`}>
                  {team.winPct}
                </td>
                <td className={`${cellSpacingRight}`}>{team.gamesBehind}</td>
                <td className={`${cellSpacingRight}`}>
                  <Hot team={team} />
                </td>
                <td className={`${cellSpacingCenter}`}>
                  <Streak team={team} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function Standings() {
  const [standings, setStandings] = useState({ east: [], west: [] })

  useEffect(() => {
    const fetchStandings = async () => {
      const result = await API.graphql({
        query: queries.standings
      })
      const conf = _get(result, 'data.standings.league.standard.conference', {
        east: [],
        west: []
      })
      setStandings(conf)
    }
    fetchStandings()
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashette - NBA Standings</title>
        <meta name="description" content="NBA Standings" />
      </Helmet>
      <div className="mx-auto max-w-6xl my-4 md:my-8 px-4 text-gray-800 antialiased">
        <div className="flex items-center justify-center">
          <div className="mr-2">
            <h1 className="text-center font-bold text-gray-800 text-2xl whitespace-no-wrap">
              Standings
            </h1>
          </div>
        </div>
        <Conference conf={standings.east} title={'eastern conference'} />
        <Conference conf={standings.west} title={'western conference'} />
      </div>
    </>
  )
}

export default Standings

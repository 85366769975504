import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ScoreCard from './ScoreCard'
import PBP from './PBP'
import Chatter from './Chatter'
import BoxScore from './BoxScore'
import { gameSubsetForPBP, useScores, toM, Loading } from './constants'
import Helmet from 'react-helmet'
import Analytics from '@aws-amplify/analytics'

const VIEWS = {
  PBP: {
    id: 1,
    link: 'Play-by-Play',
    css: '',
  },
  BOXSCORE: {
    id: 2,
    link: 'Box Score',
    css: '',
  },
  CHATTER: {
    id: 3,
    link: 'Chatter',
    css: 'md:hidden',
  },
}
const VIEW_ORDER = ['PBP', 'BOXSCORE', 'CHATTER']

function Game() {
  const { date, gameId } = useParams()
  const { scores, fetching } = useScores(date)
  const [view, setView] = useState({ current: VIEWS.PBP.id })

  const game = scores.games.find((g) => g.gameId === gameId)
  const sectionCss = 'w-full md:w-1/2 mx-0 md:mx-2'

  if (!game && !fetching) {
    return <div className="p-8 mx-auto text-center">Game not found</div>
  }
  if (fetching) {
    return <Loading />
  }

  Analytics.record({
    name: 'gameVisit',
    attributes: { date, gameId },
  })

  const title = !game
    ? `${date} - ${gameId}`
    : `${game.vTeam.triCode} vs. ${game.hTeam.triCode} on ${toM(date).format(
        'L'
      )}`
  return (
    <>
      <Helmet>
        <title>Dashette - NBA Game - {title}</title>
        <meta name="description" content="NBA Standings" />
      </Helmet>
      <div className="max-w-6xl px-4 mx-auto my-4 antialiased text-gray-800 md:my-8">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center md:flex-row">
            <h1 className="font-bold text-center text-gray-800 md:text-2xl">
              <span>{game.vTeam.team.fullName}</span>
              <span className="px-2 text-sm text-gray-600 uppercase">vs.</span>
              <span>{game.hTeam.team.fullName}</span>
            </h1>
            {game.isGameActivated ? (
              <div className="mt-2 text-center md:mt-0 md:ml-4">
                <span className="px-3 py-1 text-xs font-extrabold tracking-wide text-green-700 uppercase whitespace-no-wrap bg-green-300 rounded-full">
                  &bull; in progress
                </span>
              </div>
            ) : null}
          </div>
          {!game.isGameActivated ? (
            <div>{toM(date).format('dddd, MMM D')}</div>
          ) : null}
        </div>

        <div className="flex flex-col mt-4 md:flex-row">
          <div className={sectionCss}>
            <ScoreCard game={game} clickable={false} />
            <div>
              <ul className="flex justify-center px-4 my-4 text-sm md:text-base">
                {VIEW_ORDER.map((k, i) => {
                  const cv = VIEWS[k]
                  const active = cv.id === view.current
                  return (
                    <li key={i}>
                      <button
                        onClick={() => setView({ current: cv.id })}
                        className={`mx-2 ${cv.css} ${
                          active ? 'border-b-2 border-blue-500' : ''
                        }`}
                      >
                        {cv.link}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
            <PBP
              game={gameSubsetForPBP(game)}
              className="mt-3"
              viewId={VIEWS.PBP}
              isCurrent={view.current === VIEWS.PBP.id}
            />
            <BoxScore
              date={date}
              game={game}
              viewId={VIEWS.BOXSCORE}
              isCurrent={view.current === VIEWS.BOXSCORE.id}
            />
          </div>
          <Chatter
            className={sectionCss}
            viewId={VIEWS.CHATTER}
            isCurrent={view.current === VIEWS.CHATTER.id}
          />
        </div>
      </div>
    </>
  )
}

export default Game

import React from 'react'

function Chatter({ viewId, className, isCurrent }) {
  return (
    <div className={className + ' boxscore'}>
      <div className={`md:block chatter ${isCurrent ? '' : 'hidden'}`}>
        <div className=" bg-white p-2 rounded-lg shadow-md border">
          Discussion
        </div>
      </div>
    </div>
  )
}

export default Chatter

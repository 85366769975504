import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import _get from 'lodash.get'
import { NavLink } from 'react-router-dom'
import { getStatus, GAME_STATUS, clock } from './constants'
import PlayByPlayTicker from './PlayByPlayTicker'

const cn = 'text-right text-gray-600 align-middle font-semibold tracking-wide'
const cnxs = 'text-xs'
const outcn = 'bg-white p-2 rounded-lg border shadow-md'

function ScoreCard({ game, clickable = true }) {
  return clickable ? (
    <NavLink
      to={`/scores/${game.startDateEastern}/${game.gameId}`}
      className={
        outcn +
        ' hover:border-blue-400 focus:shadow-outline focus:outline-none focus:border-blue-400'
      }
    >
      <ScoreCardInner {...{ game }} />
    </NavLink>
  ) : (
    <div className={outcn}>
      <ScoreCardInner {...{ game }} />
    </div>
  )
}

function ScoreCardInner({ game }) {
  const { vTeam, hTeam } = game
  const teams = [vTeam, hTeam]
  const isHomeTeamWinner = parseInt(hTeam.score) > parseInt(vTeam.score)
  const watch = _get(game, 'watch.broadcast.broadcasters.national[0]')
  // console.log(watch)
  const status = getStatus(game)
  // const isLive = status === GAME_STATUS.LIVE
  const teamColSpan =
    status === GAME_STATUS.TBP ? 'col-span-7' : 'col-span-4 md:col-span-5'
  const periodColSpan =
    status === GAME_STATUS.TBP
      ? 'col-span-1 hidden'
      : 'col-span-6 md:col-span-5'
  const indicatorColSpan =
    status === GAME_STATUS.TBP ? 'col-span-5' : 'col-span-2'
  return (
    <div className="ScoreCard select-none ">
      <div>
        <div className="grid grid-rows-1 grid-cols-12 gap-2 col-gap-1 border-b">
          <div className={teamColSpan}>
            <div className="flex h-6 items-center">
              <span className="text-gray-600 text-xs uppercase font-extrabold tracking-wide text-left">
                {status === GAME_STATUS.TBP
                  ? moment(game.startTimeUTC)
                      .tz(moment.tz.guess())
                      .format('LT z')
                  : status === GAME_STATUS.ENDED
                  ? 'Final'
                  : clock(game)}
              </span>
            </div>
          </div>
          <div className={periodColSpan}>
            <PeriodScoreHeaders game={game} />
          </div>
          <div className={indicatorColSpan}>
            <PeriodIndicator {...{ game, status, watch }} />
          </div>
        </div>

        <div className="grid grid-rows-2 grid-cols-12 gap-2 col-gap-1 mt-2">
          <div className={teamColSpan}>
            <LogoAndTeamNameForGrid team={vTeam} status={status} />
          </div>
          <div className={`${periodColSpan} row-span-2`}>
            <PeriodScores teams={teams} />
          </div>
          <div className="col-span-2 row-span-2">
            <GameTotal {...{ teams, isHomeTeamWinner, status }} />
          </div>
          <div className={teamColSpan}>
            <LogoAndTeamNameForGrid team={hTeam} status={status} />
          </div>
        </div>

        <PlayByPlayTicker game={game} status={status} />
      </div>
    </div>
  )
}

function LogoAndTeamNameForGrid({ team, status }) {
  const isTBP = status === GAME_STATUS.TBP
  return (
    <div className="flex truncate whitespace-no-wrap">
      <div className="w-8 h-8 lg:h-10 md:w-16 flex-shrink-0">
        <img
          src={`https://cdn.nba.net/assets/logos/teams/secondary/web/${team.triCode}.svg`}
          alt={team.team ? team.team.fullName : team.triCode}
          className="md:w-full h-8 lg:h-12 object-cover object-center"
        />
      </div>
      <div className="flex-grow items-start flex flex-col ml-2">
        <div className="flex flex-col flex-grow justify-center">
          <div className="truncate hidden md:block">
            {team.team ? team.team.nickname : team.triCode}
          </div>
          <div className="truncate md:hidden">
            {!isTBP
              ? team.triCode
              : team.team
              ? team.team.nickname
              : team.triCode}
          </div>
          <div className="hidden lg:block text-gray-400 text-xs tracking-wide">
            ({team.win} - {team.loss})
          </div>
        </div>
      </div>
    </div>
  )
}

function PeriodScores({ teams }) {
  const n = teams[0].linescore.length
  const scores = teams.reduce((prev, curr) => prev.concat(curr.linescore), [])
  return (
    <div className={`grid grid-rows-2 grid-cols-${n} gap-2 h-full`}>
      {scores.map((q, i) => (
        <div key={i} className="flex items-center">
          <div className={cn + ' ' + cnxs + ' flex-grow'}>{q.score}</div>
        </div>
      ))}
    </div>
  )
}

function PeriodIndicator({ game, status, watch }) {
  return (
    <div className="flex h-6 items-center justify-end">
      {status !== GAME_STATUS.ENDED && watch ? (
        <div className="text-green-600">
          <span className="text-xs uppercase font-extrabold tracking-wide text-left">
            {watch.shortName}
          </span>
          <abbr title={watch.shortName}>
            <svg
              className="stroke-current h-4 inline-block "
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={status === GAME_STATUS.LIVE ? 'currentColor' : 'none'}
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="23 7 16 12 23 17 23 7"></polygon>
              <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
            </svg>
          </abbr>
        </div>
      ) : status === GAME_STATUS.ENDED ? (
        <div className="text-right uppercase text-gray-600 align-middle font-semibold tracking-wide text-xs flex-grow font-extrabold">
          <span className="md:hidden">T</span>
          <span className="hidden md:inline-block">Total</span>
        </div>
      ) : null}
    </div>
  )
}

function PeriodScoreHeaders({ game }) {
  const periods = game.hTeam.linescore.map((q, i, arr) => {
    if (i < 4) {
      return i + 1
    }
    if (arr.length > 5) {
      return `OT${i - 3}`
    }
    return 'OT'
  })
  const n = periods.length
  return (
    <div className={`grid grid-rows-1 grid-cols-${n} gap-2 h-full`}>
      {periods.map((p, i) => (
        <div key={i} className="flex items-center">
          <div className={cn + ' ' + cnxs + ' flex-grow font-extrabold'}>
            {p}
          </div>
        </div>
      ))}
    </div>
  )
}

function GameTotal({ teams, isHomeTeamWinner, status }) {
  const scores = teams.map((t, i) => ({
    score: t.score,
    win: i ? isHomeTeamWinner : !isHomeTeamWinner, // 0 index is the visiting team
    isLive: status === GAME_STATUS.LIVE
  }))
  return (
    <div className={`grid grid-rows-2 grid-cols-1 gap-2 h-full`}>
      {scores.map((q, i) => (
        <div key={i} className="flex items-center">
          <ScoreSquareForGrid {...q} />
        </div>
      ))}
    </div>
  )
}

function ScoreSquareForGrid({ score, win, isLive }) {
  const [color, setColor] = useState(isLive ? 'text-red-600' : '')
  useEffect(() => {
    if (!isLive) {
      return
    }
    setColor('text-red-600')
    const timeoutID = setTimeout(() => {
      setColor('')
    }, 4000)
    return () => {
      clearTimeout(timeoutID)
    }
  }, [score, isLive])
  return (
    <div
      className={`${
        win ? '' : 'text-gray-500'
      } flex-grow text-right align-middle font-bold self-center ${color} transition duration-1000 ease-in-out`}
    >
      {score}
    </div>
  )
}

export default ScoreCard

import React from 'react'
import { GAME_STATUS } from './constants'

import _get from 'lodash.get'

function PlayByPlayTicker({ game, status }) {
  const tickerCssName = 'flex-grow flex justify-between items-start py-1  px-2'
  const lastPlay = _get(game, 'lastPlay')
  const lastPlayDesc = _get(game, 'lastPlay.formatted.description')
  const nugget = _get(game, 'nugget.text')

  // console.log(lastPlay, nugget)
  if (!lastPlay && !nugget) {
    return null
  }
  return (
    <div className="text-xs text-center text-gray-700 bg-gray-100 rounded-lg shadow-md font-semibold mt-4">
      {status === GAME_STATUS.ENDED && nugget ? (
        <div className="py-1 px-2 text-center">{nugget}</div>
      ) : null}

      {status === GAME_STATUS.LIVE && lastPlayDesc ? (
        lastPlay.teamId ? (
          [game.hTeam, game.vTeam]
            .filter(t => t.teamId === lastPlay.teamId)
            .map((team, i) => (
              <div className={tickerCssName} key={team.teamId}>
                <div>{team.triCode}</div>
                <div className="text-center px-2">
                  {lastPlayDesc.replace(/^\s*\w+\s+-\s+/, '')}
                </div>
                <div>{lastPlay.clock}</div>
              </div>
            ))
        ) : (
          <div className="py-1 px-2 text-center'">{lastPlayDesc}</div>
        )
      ) : null}
    </div>
  )
}

export default PlayByPlayTicker

import { selectionSet, teamStandingSelectionSect, aPlay } from './base'
export const scores = /* GraphQL */ `
  query Scores($query: QueryScoresQueryInput!) {
    scores(query: $query) {
      ${selectionSet}
    }
  }
`

export const standings = /* GraphQL */ `
query Standings {
  standings {
    league {
      standard {
        conference {
          east {
            ${teamStandingSelectionSect}
          }
          west {
            ${teamStandingSelectionSect}
          }
        }
      }
    }
  }
}
`

export const pbp = /* GraphQL */ `
query Pbp($query: QueryPbpQueryInput!) {
  pbp(query: $query) {
    plays {
      ${aPlay}
    }
  }
}
`

const boxDetails = `
min
fga
fgm
fta
ftm
tpa
tpm
totReb
assists
pFouls
points
plusMinus
`

export const boxscore = /* GraphQL */ `
  query BoxScore($query: QueryBoxscoreQueryInput!) {
    boxscore(query: $query) {
      stats {
        activePlayers {
          firstName
          lastName
          personId
          teamId
          pos
          isOnCourt
          ${boxDetails}
        }
        vTeam {
          fastBreakPoints
          totals {
          ${boxDetails}
          }
        }
        hTeam {
          fastBreakPoints
          totals {
          ${boxDetails}
          }
        }
      }
    }
  }
`

import React, { useEffect, useState, useMemo } from 'react'
import API from '@aws-amplify/api'
import * as queries from './graphql/queries'
import '@aws-amplify/pubsub'
import { periods, playId } from './constants'
import { ResponsiveLine } from '@nivo/line'

function usePlayByPlay({
  period: currentPeriod,
  gameId,
  date,
  isGameActivated,
  lastPlay
}) {
  const [plays, setPlays] = useState([])
  const [period, setPeriod] = useState(null)
  const [lastIndex, setLastIndex] = useState(null)

  useEffect(() => {
    const fetchPlays = async period => {
      const result = await API.graphql({
        query: queries.pbp,
        variables: { query: { date, gameId, period } }
      })
      // console.log('plays ->', result)
      const plays = [...result.data.pbp.plays]
      return plays
    }
    const p = currentPeriod
    if (p === 0) {
      return
    }
    const fetchAll = async () => {
      const allPlays = [...new Array(p)].map(
        async (_, i) => await fetchPlays(i + 1)
      )

      // const allPlays = []
      // for (let i = 1; i <= currentPeriod; i++) {
      //   allPlays.push(fetchPlays(i))
      // }

      // console.log(allPlays)

      const all = await Promise.all(allPlays)
      setPlays(all)
      setPeriod(p)
      setLastIndex(all[p - 1].length - 1)
    }
    fetchAll()
  }, [currentPeriod, date, gameId])

  useEffect(() => {
    if (!period || period !== currentPeriod || !lastPlay) {
      return
    }
    console.log('new play')
    console.log('  ->', lastPlay.formatted.description, lastPlay.index)
    const oPi = plays[currentPeriod - 1].length - 1
    console.log(
      '  ->',
      plays[currentPeriod - 1][oPi].formatted.description,
      oPi
    )
    console.log()
    console.log()

    if (
      isGameActivated &&
      lastPlay &&
      // plays.length === currentPeriod &&
      lastPlay.index > lastIndex
    ) {
      plays[currentPeriod - 1].push(lastPlay)
      setPlays([...plays])
      setLastIndex(lastPlay.index)
    }
    return () => {}
  }, [period, lastPlay, currentPeriod, isGameActivated, plays, lastIndex])
  return plays
}

function PlayDetails({ text }) {
  const [extended, setExtended] = useState(false)
  var [firstPlay, ...otherPlays] = useMemo(
    () =>
      text
        .trim()
        .replace(/^\s*\w+\s+-\s+/, '')
        .split(/\)\s/)
        .map((s, i, a) => s + (a.length > 1 && i + 1 < a.length ? ')' : '')),
    [text]
  )
  if (otherPlays.length > 1) {
    // console.log(firstPlay, otherPlays)
    if (otherPlays[0].match(/\(\d FTA\)/)) {
      firstPlay += ` ${otherPlays.shift().trim()}`
      // referee info? not interested
      otherPlays.shift()
    }
  }

  if (firstPlay.endsWith('PF)')) {
    // referee info? not interested
    otherPlays.shift()
  }

  return (
    <>
      <span className="flex justify-between">
        <span>{firstPlay}</span>
        {otherPlays.length ? (
          <span>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-gray-400 text-sm inline ml-2 h-4 cursor-pointer"
              onClick={() => setExtended(ext => !ext)}
            >
              {!extended ? (
                <>
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                  <line x1="12" y1="8" x2="12" y2="16" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                </>
              ) : (
                <>
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                </>
              )}
            </svg>
          </span>
        ) : null}
      </span>
      <span className={extended ? 'inline-block' : 'hidden'}>
        {otherPlays.map((p, i) => (
          <span key={i}>{p}</span>
        ))}
      </span>
    </>
  )
}

function PBP({ className, game, viewId, isCurrent, currentViewId }) {
  const plays = usePlayByPlay({
    period: game.period.current,
    gameId: game.gameId,
    date: game.date,
    isGameActivated: game.isGameActivated,
    lastPlay: game.lastPlay
  })
  const [visIndex, setVisIndex] = useState(0)
  const [scoresOnly, setScoresOnly] = useState(false)
  const [sortFPF, setSortFPF] = useState(true)
  const [showPlays, setShowPlays] = useState([])
  console.log('game', JSON.stringify(game, null, 2))
  // const teams = {}
  // teams[game.hTeam.teamId] = game.hTeam
  // teams[game.vTeam.teamId] = game.vTeam
  console.log('plays ->', plays)
  // console.log(JSON.stringify(game, null, 2))

  useEffect(() => {
    const id = setTimeout(() => {
      let temp = plays.map(qp => [...qp])
      if (!sortFPF) {
        temp = temp.map(qp => qp.reverse())
      }
      if (scoresOnly) {
        temp = temp.map(qp =>
          qp.filter(
            p =>
              p.isScoreChange ||
              p.formatted.description.startsWith('START OF') ||
              p.formatted.description.startsWith('END OF')
          )
        )
      }
      setShowPlays(temp)
    })
    return () => {
      console.log('clear pbp timeout')
      clearTimeout(id)
    }
  }, [plays, scoresOnly, sortFPF])

  // console.log(showPlays)

  const tickerCssName = 'text-xs flex-grow flex justify-between items-start'
  const qBtnCss =
    'hover:text-blue-800 font-semibold focus:outline-none focus:shadow-outline rounded px-2'

  return (
    <div className={`pbp  ${className} ${isCurrent ? '' : 'hidden'}`}>
      <PlayChart plays={plays} game={game} />
      <div className="pbp mt-4">
        <div className=" bg-white p-2 rounded-lg shadow-md border">
          <ul className="flex border-b pb-2 mb-2 text-sm">
            {periods(game, true).map((per, i) => (
              <li className="mr-2 md:mr-4" key={i}>
                <button
                  onClick={() => setVisIndex(i)}
                  className={`${qBtnCss} ${
                    i === visIndex ? 'bg-blue-500 text-white' : 'text-blue-500'
                  }`}
                >
                  {per}
                </button>
              </li>
            ))}
            <li className="ml-auto mr-2 md:mr-4">
              <button
                onClick={() => setSortFPF(s => !s)}
                className={`${qBtnCss} text-blue-500 border border-blue-500`}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-down-circle h-5"
                >
                  {sortFPF ? (
                    <>
                      <polyline points="7 13 12 18 17 13"></polyline>
                      <polyline points="7 6 12 11 17 6"></polyline>
                    </>
                  ) : (
                    <>
                      <polyline points="17 11 12 6 7 11"></polyline>
                      <polyline points="17 18 12 13 7 18"></polyline>
                    </>
                  )}
                </svg>
              </button>
            </li>
            <li className="">
              <div className="flex items-center">
                <div
                  className={`bg-gray-200 relative w-6 h-3 p-1 rounded-full cursor-pointer`}
                  onClick={() => setScoresOnly(s => !s)}
                >
                  <div
                    style={{
                      right: scoresOnly ? '0' : '100%',
                      marginRight: scoresOnly ? '' : '-0.75rem'
                    }}
                    className={`block absolute top-0 h-3 w-3 p-0 m-0 rounded-full bg-blue-500 focus:outline-none transition-all duration-100 ease-linear`}
                  ></div>
                </div>
                <button
                  className="ml-1 focus:outline-none"
                  onClick={() => setScoresOnly(s => !s)}
                >
                  scores
                </button>
              </div>
            </li>
          </ul>
          {showPlays.map((qp, i) => (
            <div
              className={`${tickerCssName} ${visIndex === i ? '' : 'hidden'}`}
              key={`period-${i}`}
            >
              <table className="table-auto w-full">
                <tbody>
                  {qp.map((play, j) => {
                    const props = {
                      key: playId(play),
                      play,
                      scoresOnly,
                      prevPlay: qp[j + (sortFPF ? -1 : 1)],
                      code: play.teamId
                        ? game.teams[play.teamId].triCode
                        : null,
                      even: j % 2
                    }
                    return <PBPRow {...props} />
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function PBPRow({ play, prevPlay, scoresOnly, even, code }) {
  const vScoreDiff = !prevPlay ? false : prevPlay.vTeamScore !== play.vTeamScore
  const hScoreDiff = !prevPlay ? false : prevPlay.hTeamScore !== play.hTeamScore
  const bold = !scoresOnly && (vScoreDiff || hScoreDiff) ? 'font-semibold' : ''
  const bg = even ? 'bg-gray-100' : ''
  return (
    <tr className={`${bg} ${bold}`}>
      <td className="whitespace-no-wrap text-right pl-1 pr-2">
        {play.clock.replace('00:', '')}
      </td>
      <td className="text-center w-8">
        {code ? (
          <img
            src={`https://cdn.nba.net/assets/logos/teams/secondary/web/${code}.svg`}
            alt={code}
            className="h-8 w-8"
          />
        ) : null}
      </td>
      <td className="px-2 md:px-4 py-2">
        <PlayDetails text={play.formatted.description} />
      </td>
      <td className="whitespace-no-wrap text-right pr-1">
        <span className={vScoreDiff ? 'text-red-600' : ''}>
          {play.vTeamScore}
        </span>
        <span> - </span>
        <span className={hScoreDiff ? 'text-red-600' : ''}>
          {play.hTeamScore}
        </span>
      </td>
    </tr>
  )
}

function timetoBucketIndex(time, total, bucketSize) {
  const now = total - clockTot(time)
  const bucketIndex = Math.ceil(now / bucketSize)
  // console.log(time, '->', now, '->', bucketIndex)
  return bucketIndex
}

function clockTot(time) {
  const match = time.match(/^(\d\d):(\d\d)/)
  const min = parseInt(match[1])
  const sec = parseInt(match[2])
  return min * 60 + sec
}

function qtoBucketMap({ qp, bucketSize, seriesBuild, index }) {
  const periodLength = clockTot(
    qp.find(p => p.formatted.description.startsWith('START OF')).clock
  )
  // console.log('period length', qp[0], periodLength)
  // const numOfBuckets = periodLength / bucketSize
  const series = seriesBuild.series
  const offset = seriesBuild.offset
  let lastBucketIndex = series[0].data[series[0].data.length - 1][0]

  const updatedSeries = qp
    .filter(p => p.isScoreChange)
    .reduce((prev, curr) => {
      const index =
        offset + timetoBucketIndex(curr.clock, periodLength, bucketSize)
      // console.log('off and index ->', offset, index, curr.clock)
      const x = index
      const v = { x, y: curr.vTeamScore, clock: curr.clock, index }
      const h = { x, y: curr.hTeamScore, clock: curr.clock, index }
      // if (curr.teamId === game.hTeam.teamId) {
      //   prev[0].data[index] = [index, curr.hTeamScore]
      // } else {
      //   prev[1].data[index] = [index, curr.vTeamScore]
      // }
      if (lastBucketIndex === index) {
        prev[0].data[prev[0].data.length - 1] = v
        prev[1].data[prev[1].data.length - 1] = h
      } else {
        prev[0].data.push(v)
        prev[1].data.push(h)
        lastBucketIndex = index
      }
      return prev
    }, series)
  return {
    series: updatedSeries,
    offset: offset + periodLength / bucketSize
  }
  // .map(set => {
  //   const final = {
  //     ...set,
  //     data: set.data.filter(s => s)
  //   }
  //   console.log(final)
  //   return final
  // })
}

function PlayChart({ plays, game }) {
  const BUCKET_SIZE = 10
  const initSeries = [
    { id: game.vTeam.nickname, data: [{ x: 0, y: 0 }] },
    { id: game.hTeam.nickname, data: [{ x: 0, y: 0 }] }
  ]
  const data = React.useMemo(() => {
    const initSeriesBuild = {
      series: initSeries,
      offset: 0
    }
    const build =
      plays.reduce((p, c) => p + c.length, 0) > 0
        ? plays.reduce((seriesBuild, qp, index) => {
            return qtoBucketMap({
              qp,
              seriesBuild,
              index,
              bucketSize: BUCKET_SIZE
            })
          }, initSeriesBuild)
        : initSeriesBuild
    // console.log('series -> ', build.series)
    return build.series
  }, [initSeries, plays])

  const gridXValues = useMemo(() => {
    return game.hTeam.linescore.map((_, i) => {
      if (i < 4) {
        return 72 * i
      }
      return 72 * 4 + 5 * 60 * (i - 4)
    })
  }, [game.hTeam.linescore])
  const gridYValues = [25, 50, 75, 100, 125, 150, 175, 200]

  const commonProperties = {
    data,
    margin: { top: 20, right: 10, bottom: 40, left: 40 },
    curve: 'monotoneX',
    xScale: {
      type: 'linear',
      min: 0,
      max: 'auto'
    },
    animate: false,
    enableSlices: 'x',
    enablePoints: false,
    axisBottom: {
      format: x => {
        if (x < 72 * 4) {
          return `Q${x / 72 + 1}`
        }
        return `OT${(x - 72 * 4) / 30 + 1}`
      },
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: gridXValues
    },
    gridXValues,
    axisLeft: { tickValues: gridYValues },
    gridYValues,
    legends: [
      {
        anchor: 'top',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -5,
        itemsSpacing: 10,
        itemDirection: 'left-to-right',
        itemWidth: 100,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]
  }

  return (
    <div className="chart mt-4">
      <div className=" bg-white p-2 rounded-lg shadow-md border h-48">
        <div className="h-full w-full">
          <ResponsiveLine {...commonProperties} />
        </div>
      </div>
    </div>
  )
}

export default PBP

import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import API, { graphqlOperation } from '@aws-amplify/api'
import '@aws-amplify/pubsub'
import { scores } from './graphql/queries'
import { onUpdateScores } from './graphql/subscriptions'
import _get from 'lodash.get'

export const GAME_STATUS = {
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  TBP: 'TO_BE_PLAYED'
}

export function getStatus(game) {
  if (game.isGameActivated) {
    return GAME_STATUS.LIVE
  }
  if (game.endTimeUTC) {
    return GAME_STATUS.ENDED
  }
  return GAME_STATUS.TBP
}

export function periodInfo(period) {
  const p = period.type === 0 ? 'Q' : 'OT'
  return {
    p,
    end: period.isEndOfPeriod,
    n: period.current,
    a: new Array(period.current)
  }
}

export function periods(game, withQuarter = false) {
  return game.hTeam.linescore.map((q, i, arr) => {
    if (i < 4) {
      return `${withQuarter ? 'Q' : ''}${i + 1}`
    }
    if (arr.length > 5) {
      return `OT${i - 3}`
    }
    return 'OT'
  })
}

export function clock(game) {
  const period = game.period

  if (period.isHalftime) {
    return 'halftime'
  }
  // const p = period.type === 0 ? 'Q' : 'OT'
  const { p, end, n } = periodInfo(period)
  if (end) {
    return `End of ${p}${n}`
  }

  if (n === 0) {
    return 'pregame'
  }

  return `${p}${n} ${game.clock}`
}

export function gameSubsetForPBP(game) {
  return {
    period: { ...game.period },
    gameId: game.gameId,
    date: game.startDateEastern,
    isGameActivated: game.isGameActivated,
    hTeam: {
      teamId: game.hTeam.teamId,
      linescore: game.hTeam.linescore,
      nickname: game.hTeam.team.nickname
    },
    vTeam: {
      teamId: game.vTeam.teamId,
      linescore: game.vTeam.linescore,
      nickname: game.vTeam.team.nickname
    },
    teams: {
      [game.hTeam.teamId]: {
        teamId: game.hTeam.teamId,
        triCode: game.hTeam.triCode
      },
      [game.vTeam.teamId]: {
        teamId: game.vTeam.teamId,
        triCode: game.vTeam.triCode
      }
    },
    lastPlay: game.lastPlay
  }
}

export const playId = play => {
  return [
    play.clock,
    play.teamId || 'NONE',
    play.formatted.description.replace(/\s/, '')
  ].join('-')
}

export function toM(date) {
  return moment(date, 'YYYYMMDD')
}
export function useScores(date) {
  const [lastDate, setLastDate] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [gameScores, setGameScores] = useState({ games: [], numGames: 0 })

  const found = gameScores.games.find(g => g.isGameActivated)
  const isCurrent = found ? true : false

  useEffect(() => {
    const fetchScores = async () => {
      setFetching(true)
      setLastDate(toM(date))
      try {
        const result = await API.graphql({
          query: scores,
          variables: {
            query: {
              date: toM(date)
                .tz('America/New_York')
                .format('YYYYMMDD')
            }
          }
        })
        setGameScores(result.data.scores)
      } catch (error) {
        console.log('err fetching scores ->', error)
      } finally {
        setFetching(false)
      }
    }
    console.log(fetching, date, lastDate ? lastDate.format('L') : null)
    if (fetching || toM(date).isSame(lastDate)) {
      return
    }
    fetchScores()
    return () => {
      console.log('changing')
    }
  }, [fetching, date, lastDate])

  useEffect(() => {
    var subscription = null
    if (!isCurrent) {
      return
    }
    async function sub() {
      subscription = API.graphql(graphqlOperation(onUpdateScores)).subscribe({
        next: notification => {
          console.log('*** NEW SCORE ***')
          setGameScores(notification.value.data.onUpdateScores)
        }
      })
    }
    sub()
    return () => {
      console.log('cleaning subscription')
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [isCurrent])

  return { scores: gameScores, isCurrent, fetching }
}

const QUERY_STATE = {
  HOLD: 0,
  READY: 1,
  FETCHING: 2,
  COMPLETED: 3,
  ERROR: 4
}

export function useQuery({
  query,
  path,
  variables,
  onStart = false,
  def = null
}) {
  const [result, setResult] = useState(def)
  const [error, setError] = useState(null)
  const [machine, setMachine] = useState(
    onStart ? QUERY_STATE.READY : QUERY_STATE.HOLD
  )

  // const shouldExecute = execute === undefined ? true : execute

  useEffect(() => {
    async function fetch() {
      setMachine(QUERY_STATE.FETCHING)
      try {
        const _result = await API.graphql({
          query,
          variables
        })
        setResult(r => _get(_result, 'data.' + path) || r)
        setMachine(QUERY_STATE.COMPLETED)
      } catch (_error) {
        setMachine(QUERY_STATE.ERROR)
        setError(_error)
      }
    }
    if (machine !== QUERY_STATE.READY) {
      return
    }
    fetch()
    return () => {
      console.log('clean up useQuery')
    }
  }, [machine, variables, query, path])

  return [
    { result, fetching: machine === QUERY_STATE.FETCHING, error },
    React.useCallback(() => setMachine(QUERY_STATE.READY), [])
  ]
}

export function Loading() {
  return (
    <div className="mx-auto p-8 text-center text-green-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-loader inline spinner"
      >
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
      </svg>
    </div>
  )
}
